import React from "react";
import HeroSection from "./HeroSection";
import HeroTwoSection from "./HeroTwoSection";
import HeroThreeSection from "./HeroThreeSection";
import PaidBooks from "./PaidBooks";
import OrderBooks from "./PreOrderBooks";

import HeroFourSection from "./HeroFourSection";
import HeroFiveSection from "./HeroFiveSection";
import HeroSixSection from "./HeroSixSection";
import HeroSevenSection from "./HeroSevenSection";
import ComingSoonSection from "./ComingSoonSection";
import TheFooter from "../Footer/TheFooter";
import Advertisement from "./Advertisement";
import ExploreContainer from "../testComponent/ExploreContainer";
import ServiceList from "../testComponent/ServiceList";

const Sections = () => {
  //Rendering every of the section components
  return (
    <main>
      <HeroSection />
      {/* <Advertisement /> */}

      {/* <HeroTwoSection />
      <HeroFourSection /> */}
      <OrderBooks />
      <PaidBooks />


      <HeroThreeSection />

      {/* <ServiceList/> */}
      {/* <ExploreContainer/> */}

      {/* <HeroFiveSection /> */}
      <HeroSixSection />
      {/* <HeroThreeSection /> */}
      {/* <HeroTwoSection /> */}

      {/* <ComingSoonSection /> */}
      {/* <OrderBooks /> */}

      {/* <HeroSection /> */}

      <TheFooter />

      {/* <HeroSevenSection /> */}


    </main>
  );
  //END
};

export default Sections;
