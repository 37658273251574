import React from 'react';
import Slider from 'react-slick';
import classes from './AlbumAnnouncement.module.css';
import playIcon from '../../assets/icons/stream.svg'; // Path to your play icon

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logoImage from '../../assets/image/kerrusso.jpeg'; // Path to your logo image
import HAB from "../../asset/amb.jpeg";
import worldEvangelization from "../../asset/world_evangelization.png";

// Updated data with isLargeImage property
const slidesData = [
  {
    title: "The Album: Kērussō is out",
    description1: "After touching over 105,000 lives with the first five singles, the full album is now available, featuring three brand-new songs. Get ready to experience something like never before!",
    description2: "These three phenomenal songs bring the album to a powerful and inspiring finale. Get ready to be blessed by these powerful sounds.",
    image: logoImage,
    showButton: true,
    isLargeImage: false
  },
  {
    title: "Ambidextrous Christianity", 
    description1: "AMBIDEXTROUS CHRISTIANITY PRE-ORDER!💥 Out in hard copy print on Sunday, the 29th of December, 2024, the explosive Book- AMBIDEXTROUS CHRISTIANITY! It's a book that will transform the understanding of the believer in His assignment and the call of GOD upon him. AMBIDEXTROUS CHRISTIANITY has always been GOD'S plan, and more than ever before, it will be the order of the day in the 21st Century! AMBIDEXTROUS CHRISTIANITY will be strong believers trained in the Word, prayer, Ministry, and the things of the Spirit, yet very strong in their careers and natural endeavours as platforms to establish the rule of GOD in the world- TOTAL MEN. This book seeks to open the eyes of believers to who they have been called to be- light in the dark places of this world, and the training and discipline that goes with it. Pre-Order NOW for a discounted price on bit.ly/PreOrderAmbidextrousChristianity. Pre-order ends on Friday, 27th December, 2024.",
    description2: "",
    image: HAB,
    showButton: false,
    isLargeImage: false
  },

  // {
  //   title: "World Evangelisation", 
  //   description1: "Our books are products of the years of labour in the Teaching of GOD'S Word, across various Teaching Series, on a Bible Subject, and this book is no different.",
  //   description2: "This masterpiece is a blend of doctrine and evangelistic practice. For more than half of the book, the Gospel is purely explained in details and from various perspectives, the vision of World Evangelization is then shared, and the methods to get about evangelism is carefully and practically explained. In it, you will learn about: What the Gospel message is, The characteristics of the Spirit received at salvation, The real Gospel of Inclusion and the faults of the doctrine of Universalism, Predestination and Election in salvation, Faith for World Evangelization,  The why, message, plan and effect of World Evangelization, The responsibility, need, period, tool, method and reward of Evangelism, Types of Evangelism, and more.  Watch out for it!",
  //   image: worldEvangelization,
  //   showButton: false,
  //   isLargeImage: true
  // },
];

const AlbumAnnouncement = ({ onListenNow }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
  };

  return (
    <Slider {...settings} className={classes.slider}>
      {slidesData.map((slide, index) => (
        <div key={index}>
          <div className={classes.announcement}>
            <div className={classes.textSection}>
              <h2 className={classes.title}>{slide.title}</h2>
              <p className={classes.description}>{slide.description1}</p>
              <p className={classes.description}>{slide.description2}</p>
              {slide.showButton && (
                <button className={classes.listenButton} onClick={onListenNow}>
                  <img src={playIcon} alt="Listen" className={classes.playIcon} />
                  Listen Now
                </button>
              )}
            </div>
            <div className={classes.logoContainer}>
              <img
                src={slide.image}
                alt={slide.title}
                className={`${classes.logo} ${slide.isLargeImage ? classes.largeLogo : ''}`}
              />
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default AlbumAnnouncement;
